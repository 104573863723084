
  import { Component, Watch } from 'vue-property-decorator'
  import DynamicForm from '@/components/forms/DynamicForm.vue'
  import FormDialog from '@/components/core/form/FormDialog.vue'
  import DialogContent from '@/components/core/baseDialog/DialogContent.vue'
  import { mapActions, mapGetters } from 'vuex'
  import LaborDataContent from '@/components/person/laborData/LaborDataContent.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import ReferenceAlignment from '@/components/person/ReferenceAlignment.vue'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'

  import { plainToInstance } from 'class-transformer'
  import { extract } from '@/graphql/forms'
  import { deepCopy, fixDate, intervalFormat } from '@/utils/general'
  import { LeadActivity } from '@/entities/crm'
  import { RULES } from '@/components/forms'
  import { Form } from '@/entities/public/Resource/metadata'
  import dayjs from 'dayjs'
  import duration from 'dayjs/plugin/duration'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { LeadActivityView } from '@/components/forms/view/LeadActivityView'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import AgendaField from '@/components/forms/fields/agenda/AgendaField.vue'

  dayjs.extend(duration)

@Component({
  components: {
    AgendaField,
    Agenda,
    BankDataAlignment,
    ReferenceAlignment,
    MobileForm,
    FormTitle,
    DesktopForm,
    GDatePicker,
    AddressAlignment,
    LaborDataContent,
    DynamicForm,
    FormDialog,
    DialogContent,
    BaseForm,
  },
  methods: {
    ...mapActions('resources', ['refresh']),
    ...mapActions('resources/form', ['fetchData', 'pushData']),
  },
  computed: {
    ...mapGetters('resources', ['getForm']),
    ...mapGetters('user', ['user']),
  },
})
  export default class LeadActivityForm extends LeadActivityView {
  declare $refs: {
    form: HTMLFormElement;
    form2: HTMLFormElement;
    desktop: HTMLFormElement
  };

  showDetail = false

  fields = {
    intention: {
      items: [],
    },
  }

  vModel = {
    type: {
      id: null,
    },
    schedulingDate: null,
    notePurchase: null,
    noteSale: null,
    end: null,
    start: null,
    result: undefined,
    intention: null,
  }

  vModelNextAction = {
    type: {
      id: null,
    },
    schedulingDate: null,
    notePurchase: null,
    noteSale: null,
    start: null,
    end: null,
    intention: null,
  }

  isTimeSelected = false
  valid = false
  validNext = false
  fieldRequired = RULES.isRequired;
  isSale = false
  isPurchase = false
  leadActivity = {}
  activities = []
  personActivities = []
  newAgenda = null
  lead = null
  metadata = {}
  metadataCollection = null
  leadId = null
  title = 'Tarea'
  updateMetadata = {
    data: {},
    metadata: {},
    tasks: [],
  }

  async mounted (): Promise<void> {
    await this.setMetadata()
    this.setDetails()
  }

  setDetails () {
    const { metadataCollection, lead } = this
    this.metadata = {
      data: lead,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(lead?.id)
  }

  async getParentData (parentModel: string, parentId: string) {
    if (!parentModel || !parentId) {
      return {}
    }

    const parent = await this.fetchData({ query: { name: 'fetch', model: parentModel, params: { id: parentId } } })

    return { [parentModel.toLowerCase()]: parent }
  }

  async getLeadActivity (uid: string, model: string, value: object) {
    if (!uid) {
      const { Model } = extract({ name: 'fetch', model })
      return plainToInstance(Model, deepCopy(value))
    }

    return this.fetchData({ query: { name: 'fetch', model, params: { id: uid } }, force: true })
  }

  getVModelWithUID (leadActivity: any) {
    const noteSale = leadActivity?.notes?.find(note => note.deal.isSale)?.note || ''
    const notePurchase = leadActivity?.notes?.find(note => note.deal.isPurchase)?.note || ''
    const intention = leadActivity?.lead?.sale?.intention?.id || leadActivity?.lead?.purchase?.intention?.id
    return {
      type: {
        id: leadActivity?.type?.id,
      },
      schedulingDate: dayjs(leadActivity?.schedulingDate).format('DD/MM/YYYY'),
      notePurchase,
      noteSale,
      start: leadActivity?.timeStart,
      end: leadActivity?.timeEnd,
      result: leadActivity?.activityResult || undefined,
      intention,
    }
  }

  async setMetadata () {
    const { uid, parentModel, parentId, title } = this

    const parentData = await this.getParentData(parentModel, parentId.toString())

    const leadActivity = await this.getLeadActivity(uid, 'LeadActivity', parentData)

    const { metadata } = this.getForm('LeadActivity', 'create_lead_activity')
    this.metadataCollection = metadata

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(uid))
    }

    const { fields: { type: { computed: { queries: { items: { where } } } } } } = metadata as Form

    this.activities = await this.fetchData({
      query: {
        name: 'find',
        model: 'ActivityType',
        order: {
          order: 'asc',
        },
      },
      filter: { ...where },
    })

    if (uid) {
      this.vModel = this.getVModelWithUID(leadActivity)
    }

    this.lead = await this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id: leadActivity?.lead?.id } },
      force: true,
    })

    this.leadId = leadActivity?.lead?.id || parentId
    this.leadActivity = leadActivity

    this.fields.intention.items = await this.fetchData({
      query: { name: 'find', model: 'Intention' },
    })
  }

  async getActivitiesByLead () {
    const { idEmployee, leadId, uid, vModel, vModelNextAction } = this
    if (!leadId) return []
    const date = !uid ? vModel?.schedulingDate : vModelNextAction?.schedulingDate

    if (!date) return
    const filter = {
      _and: [
        {
          lead: {
            id_executive: {
              _eq: idEmployee,
            },
          },
        }, {
          id_closing_reason: {
            _is_null: true,
          },
        },
        {
          activity_type: { name: { _neq: 'inspection' } },
        }, {
          scheduling_date: {
            _gte: dayjs(fixDate(date)).startOf('day'),
            _lte: dayjs(fixDate(date)).endOf('day'),
          },
        }],
    }

    return this.fetchData({
      query: {
        name: 'find',
        model: 'LeadActivity',
      },
      filter,
      force: true,
    })
  }

  async send (): Promise<void> {
    if (!this.$refs.form.validate() || ((this.uid && this.vModel.result !== null) && !this.$refs.form2.validate())) {
      return
    }
    this.loadingForm = true
    const { structure, uid, formattedActivity, vModel } = this
    const noteFields = this.createFieldNote(Boolean(uid))
    const nextNoteFields = this.createFieldNextNote()

    if (uid && vModel.result !== undefined) {
      const fieldsEdit = {
        id: uid,
        activity_result: this.vModel.result,
        id_process_status: this.leadActivityStatus.closed?.[0]?.id,
        id_executed_by: this.idEmployee,
      }

      await this.pushData({ model: structure.model, fields: fieldsEdit })
    }

    const notes = this.editNotes(formattedActivity, vModel)
    if (uid && notes?.data?.length) {
      for await (const fields of notes.data) {
        await this.pushData({ model: 'DealNote', fields })
      }
    }
    const createNotes = this.createNotes(formattedActivity, vModel)
    if (uid && createNotes?.data?.length) {
      for await (const fields of createNotes.data) {
        await this.pushData({ model: 'DealNote', fields })
      }
    }

    if (!formattedActivity?.id) {
      await this.pushData({ model: structure.model, fields: noteFields })
    }

    if (vModel.result !== undefined && formattedActivity?.id) {
      await this.pushData({ model: structure.model, fields: nextNoteFields })
    }
    await this.updateDeal()
    this.$router.back()
  }

  async updateDeal () {
    const { vModel, vModelNextAction, formattedActivity } = this

    const intention = vModelNextAction?.intention || vModel?.intention
    if (formattedActivity?.lead?.purchase?.id && intention) {
      await this.pushData({
        model: 'Deal',
        fields: { id: formattedActivity?.lead?.purchase?.id, id_intention: intention },
      })
    }
    if (formattedActivity?.lead?.sale?.id && intention) {
      await this.pushData({
        model: 'Deal',
        fields: { id: formattedActivity?.lead?.sale?.id, id_intention: intention },
      })
    }
  }

  createFieldNote (create) {
    const { formattedActivity, vModel, structure } = this
    if (!create) {
      const startDate = vModel?.schedulingDate + ' ' + vModel.start
      const endDate = vModel?.schedulingDate + ' ' + vModel.end
      return {
        id_activity_type: vModel.type?.id,
        id_lead: formattedActivity.lead?.id,
        id_process_status: structure.fields?.status?.init?.value,
        scheduling_date: dayjs(fixDate(startDate), 'YYYY-MM-DD HH:mm:ss'),
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
        deal_notes: this.dealNoteCreate(formattedActivity, vModel),
      }
    }
  }

  createFieldNextNote () {
    const { formattedActivity, structure, vModelNextAction } = this
    if (!vModelNextAction?.start && !vModelNextAction?.end) return undefined
    const startDate = vModelNextAction?.schedulingDate + ' ' + vModelNextAction.start
    const endDate = vModelNextAction?.schedulingDate + ' ' + vModelNextAction.end
    return {
      id_activity_type: vModelNextAction.type?.id,
      id_lead: formattedActivity.lead.id,
      id_process_status: structure.fields?.status?.init?.value,
      scheduling_date: dayjs(fixDate(startDate), 'YYYY-MM-DD HH:mm:ss'),
      duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      deal_notes: this.dealNoteCreate(formattedActivity, vModelNextAction),
    }
  }

  createNotes (activity, data) {
    const { isSale, isPurchase } = this

    const newData = activity?.notes?.map(note => {
      if (isSale && !note?.deal?.isSale) {
        return { id_lead_activity: activity?.id, id_deal: activity?.lead?.sale?.id, note: data.noteSale }
      }
      if (isPurchase && !note?.deal?.isPurchase) {
        return { id_lead_activity: activity?.id, id_deal: activity?.lead?.purchase?.id, note: data.notePurchase }
      }
    }) || []

    return { data: newData?.filter(_ => _) }
  }

  editNotes (activity, data) {
    const { isSale, isPurchase } = this
    const newData = []

    if (isSale) {
      const noteSale = activity?.notes?.find(note => note.deal.isSale)
      if (noteSale) {
        newData.push({ id: noteSale?.id, note: data.noteSale })
      }
    }
    if (isPurchase) {
      const notePurchase = activity?.notes?.find(note => note.deal.isPurchase)
      if (notePurchase) {
        newData.push({ id: notePurchase.id, note: data.notePurchase })
      }
    }
    return { data: newData }
  }

  dealNoteCreate (activity, data) {
    const { isSale, isPurchase } = this
    const newData = []

    if (isSale && data?.noteSale?.length) {
      newData.push({ id_deal: activity?.lead?.sale?.id, note: data.noteSale })
    }
    if (isPurchase && data?.notePurchase?.length) {
      newData.push({ id_deal: activity?.lead?.purchase?.id, note: data.notePurchase })
    }
    return { data: newData }
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    const { uid } = this
    if (!uid) {
      this.vModel.end = range.end
      this.vModel.start = range.start
    } else {
      this.vModelNextAction.end = range.end
      this.vModelNextAction.start = range.start
    }

    this.isTimeSelected = Boolean(range.start && range.end)
  }

  get structure () {
    const { metadataCollection } = this

    return metadataCollection as Form
  }

  get formattedActivity () {
    const { leadActivity } = this

    return leadActivity as LeadActivity
  }

  get typeChange () {
    const { vModel, vModelNextAction, uid } = this
    return {
      id: uid && vModel.result !== undefined ? vModelNextAction.type?.id : vModel.type?.id,
      date: uid ? vModelNextAction?.schedulingDate : vModel?.schedulingDate,
    }
  }

  get change () {
    const { vModel } = this

    return JSON.stringify(vModel)
  }

  get disabledSend () {
    const { vModel, uid } = this

    return Boolean(uid && vModel.result === undefined)
  }

  get disabledVModelTime () {
    const { vModel, uid } = this

    if (uid) return true

    return !vModel.type.id || !vModel.schedulingDate
  }

  get disabledVModelNextActionTime () {
    const { vModelNextAction } = this

    return !vModelNextAction.type.id || !vModelNextAction.schedulingDate
  }

  @Watch('typeChange', { immediate: true })
  async onChange ({ id }) {
    if (!id) return
    const { activities } = this
    if (!activities?.length) {
      this.newAgenda = null
      return
    }

    const { uid, vModel, typeChange } = this
    const allowSelect = !uid ? true : Boolean(vModel.result !== undefined)
    const action = activities.find(activity => activity?.id === typeChange.id)
    this.newAgenda = { title: action, date: typeChange.date, allowSelect, lead: this.lead }
    this.personActivities = await this.getActivitiesByLead()
  }

  @Watch('formattedActivity', { deep: true, immediate: true })
  onLeadChange (val) {
    this.isSale = val?.lead?.sale || val?.notes?.find(note => note.deal.isSale)
    this.isPurchase = val?.lead?.purchase || val?.notes?.find(note => note.deal.isPurchase)
  }
  }
